import { request } from "../utils/request"
import store from "../store"

export function getAllUser(limit, param) {
    return request({
        url: "admin/users/list?limit=" + limit + "&site_id=" + store.state.site.site.id + param,
        method: "get"
    })
}
export function getListLevels() {
    return request({
        url: "admin/levels?site_id=" + store.state.site.site.id,
        method: "get"
    })
}
export function updateInfoUser(data) {
    return request({
        url: "admin/users/" + data.id,
        method: "post",
        data
    })
}
export function updateUserLevel(data) {
    data.site_id = store.state.site.site.id
    return request({
        url: "admin/users/change/level",
        method: "post",
        data
    })
}
export function updateUser(id, data) {
    data.site_id = store.state.site.site.id
    return request({
        url: "admin/users/update/" + id,
        method: "post",
        data
    })
}
export function updateUserStatus(id, data) {
    // data = {status: 0/1}
    return request({
        url: "admin/users/change-status/" + id,
        method: "post",
        data
    })
}
export function deleteUser(id) {
    let data = { id }
    return request({
        url: "admin/users/delete/" + id,
        method: "post",
        data
    })
}
export function updateLevelInfo(id, data) {
    return request({
        url: "admin/levels/update/" + id,
        method: "post",
        data
    })
}
export function getLogsAllUsers(limit = null, username = null) {
    return request({
        url:
            "admin/logs?sort_by=desc" +
            (limit ? "&limit=" + limit : "") +
            "&site_id=" +
            store.state.site.site.id +
            (username ? "&username=" + username : ""),
        method: "get"
    })
}
export function getListRefund(limit = 100, param) {
    return request({
        url: "admin/refund" + (limit ? "?limit=" + limit : "") + param,
        method: "get"
    })
}
export function getDetailRefund(param) {
    return request({
        url: "api/advertising/list" + param,
        method: "get"
    })
}
export function getToolAdmin(level_id) {
    return request({
        url: "admin/tools?site_id=" + store.state.site.site.id + (level_id ? "&level_id=" + level_id : ""),
        method: "get"
    })
}
export function updateRefund(id, data) {
    return request({
        url: "admin/refund/update/" + id,
        method: "post",
        data
    })
}
export function confirmRefund(id) {
    let data = { id }
    return request({
        url: "admin/refund/confirm/" + id,
        method: "post",
        data
    })
}

export function confirmForceRefund({ id, force }) {
    let data = { id, force }
    return request({
        url: "admin/refund/confirm/" + id,
        method: "post",
        data
    })
}

export function updateSiteInfo(data) {
    return request({
        url: "admin/sites/update/" + store.state.site.site.id,
        method: "post",
        data
    })
}
export function priceUpdate(id, data) {
    data.site_id = store.state.site.site.id
    return request({
        url: "admin/price/update/" + id,
        method: "post",
        data
    })
}
export function priceReset(data) {
    return request({
        url: "admin/sites/reset-price",
        method: "post",
        data
    })
}
export function hideTool(data) {
    data = { tool_id: data, site_id: store.state.site.site.id }
    return request({
        url: "admin/sites/hidden-tool",
        method: "post",
        data
    })
}
export function showTool(data) {
    data = { tool_id: data, site_id: store.state.site.site.id }
    return request({
        url: "admin/sites/open-tool",
        method: "post",
        data
    })
}
export function getStatisticsAll() {
    return request({
        url: "admin/statistical",
        method: "get"
    })
}
export function getStatisticsRevenue(dateFrom, dateTo) {
    return request({
        url: "admin/statistical/revenue?date_from=" + dateFrom + "&date_to=" + dateTo,
        method: "get"
    })
}
export function getStatisticsTools(dateFrom, dateTo) {
    return request({
        url: "admin/statistical/by-tool?date_from=" + dateFrom + "&date_to=" + dateTo,
        method: "get"
    })
}
export function getStatisticsUser(dateFrom, dateTo, type, limit) {
    return request({
        url:
            "admin/statistical/customer?date_from=" +
            dateFrom +
            "&date_to=" +
            dateTo +
            "&type=" +
            type +
            "&limit=" +
            limit,
        method: "get"
    })
}
// @LandingPage
export function createLandingPage(data) {
    return request({
        url: "admin/sites/create-landing-page",
        method: "post",
        data
    })
}
export function getInfoLandingPage(data) {
    return request({
        url: "api/landing-page",
        method: "post",
        data
    })
}
//Menus
export function getListMenus() {
    return request({
        url: "admin/out-site-v2/category-item",
        method: "get"
    })
}
export function createMenus(data) {
    return request({
        url: "admin/out-site-v2/category-item/create",
        method: "post",
        data
    })
}
export function updateMenus(data) {
    return request({
        url: "admin/out-site-v2/category-item/update/" + data.id,
        method: "post",
        data
    })
}
export function removeMenus(id) {
    return request({
        url: "admin/out-site-v2/category-item/remove/" + id,
        method: "get"
    })
}
export function removePackage(id) {
    return request({
        url: "admin/out-site-v2/package/remove/" + id,
        method: "get"
    })
}
//Auto Card
export function getPaymentCard() {
    return request({
        url: "admin/card",
        method: "get"
    })
}
export function updatePaymentCard(data) {
    return request({
        url: "admin/card/update",
        method: "post",
        data
    })
}
export function getRefundCount() {
    return request({
        url: "admin/refund/count",
        method: "get"
    })
}
