<template>
    <div>
        <h4 class="bold text-uppercase">Cài đặt Auto nạp Card</h4>
        <div class="row mt-4">
            <div class="col-md-8 col-12">
                <div class="form-group row">
                    <div class="col-md-3 col-12">
                        <h6 class="bold">Nhập Api Key</h6>
                    </div>
                    <div class="col">
                        <input
                            class="form-control input-light"
                            type="text"
                            placeholder="Nhập api key"
                            required=""
                            v-model="dataAPI.api_key"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-3 col-12">
                        <h6 class="bold">Nhập Api Secret</h6>
                    </div>
                    <div class="col">
                        <input
                            class="form-control input-light"
                            type="text"
                            placeholder="Nhập api secret"
                            required=""
                            v-model="dataAPI.api_secret"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-3 col-12">
                        <h6 class="bold">Nhập phí quy đổi theo %</h6>
                    </div>
                    <div class="col">
                        <input
                            class="form-control input-light"
                            type="number"
                            placeholder="Nhập phí quy đổi"
                            required=""
                            v-model="dataAPI.charge"
                        />
                        <div class="card card-white mt-2">
                            <div class="card-body py-2">
                                <h6 class="font-13 bold text-justify">
                                    Mô tả: Nạp thẻ 100,000 VND =
                                    {{ (100000 - (dataAPI.charge * 100000) / 100) | formatNumber }} {{ currency }}
                                </h6>
                                <h6 class="font-13 text-justify">
                                    - Khi nạp thẻ cào, bạn sẽ phải trả phí {{ dataAPI.charge }}% giá trị của thẻ để chi
                                    trả cho phí dịch vụ của nhà mạng.
                                </h6>
                                <h6 class="font-13 mb-0 text-justify">
                                    - Bạn nạp thẻ 100,000 sẽ mất
                                    {{ ((dataAPI.charge * 100000) / 100) | formatNumber }} phí dịch vụ và thực nhận của
                                    tài khoản bạn là
                                    {{ (((100 - dataAPI.charge) * 100000) / 100) | formatNumber }} tương ứng với
                                    {{ (((100 - dataAPI.charge) * 100000) / 100) | formatNumber }}
                                    {{ currency }}
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-3 col-12">
                        <h6 class="bold">Trạng thái kích hoạt</h6>
                    </div>
                    <div class="col">
                        <toggle-button
                            v-model="dataAPI.status"
                            :labels="{ checked: 'BẬT', unchecked: 'TẮT' }"
                            :sync="true"
                            color="#23C56C"
                            :height="30"
                            :width="79"
                            class="mt-1"
                        />
                        <button
                            @click="updatePaymentCard()"
                            class="mt-3 btn-block btn-lg btn bold btn-dark-blue"
                            type="submit"
                        >
                            Lưu Cài Đặt
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mt-3 mt-md-0 col-12">
                <div class="card card-red h-100">
                    <div class="card-body">
                        <h4>Lưu ý:</h4>
                        <p>
                            - Để tích hợp API nạp thẻ tự động, bạn hãy vào trang
                            <a class="font-bold text-white" target="_blank" href="https://trumthe247.com/"
                                >https://trumthe247.com</a
                            >
                            đăng ký tài khoản
                        </p>
                        <p class="mb-1">
                            - Truy cập địa chỉ
                            <a
                                class="font-bold text-white"
                                target="_blank"
                                href="https://trumthe247.com/dang-ky-tich-hop-api.html"
                                >https://trumthe247.com/dang-ky-tich-hop-api.html</a
                            >
                            nhấn nút THÊM WEBSITE MỚI
                        </p>
                        <p class="mb-1">+ Mục Tên miền điền: <span class="font-bold">fb-api.com</span></p>
                        <p class="mb-1">
                            + Mục URL callback điền:
                            <span class="font-bold">http://fb-api.com/api/payment-card/call-back</span>
                        </p>
                        <p class="mb-1">+ Nhấn xác nhận để lưu</p>
                        <p>
                            + Đợi hệ thống
                            <a class="font-bold text-white" target="_blank" href=" trumthe247.com"> trumthe247.com</a>
                            duyệt trong vòng 10-15p đến khi trạng thái chuyển từ ĐỢI DUYỆT sang HOẠT ĐỘNG
                        </p>
                        <p>
                            - Truy cập
                            <a
                                class="font-bold text-white"
                                target="_blank"
                                href="https://trumthe247.com/dang-ky-tich-hop-api.html"
                                >https://trumthe247.com/dang-ky-tich-hop-api.html</a
                            >
                            copy API Key và API Secret
                        </p>
                        <p class="mb-1">- Truy cập site đại lý của bạn:</p>
                        <p class="mb-1">+ Vào phần ADMIN</p>
                        <p class="mb-1">+ Vào tiếp mục CÀI ĐẶT AUTO NẠP THẺ</p>
                        <p class="mb-1">+ Dán Api Key và Api Secret của trumthe đã copy vào</p>
                        <p>+ Nhập Nhập phí quy đổi theo % bạn muốn.</p>
                        <p class="mb-1">
                            . Lưu ý trumthe đang lấy chiết khấu khoảng 35% (<a
                                class="font-bold text-white"
                                target="_blank"
                                href="https://trumthe247.com/chiet-khau-nap-the-cao.html"
                                >https://trumthe247.com/chiet-khau-nap-the-cao.html</a
                            >) bạn cần điều chỉnh chiết khẩu của bạn cao hơn 35% để tránh bị lỗ.
                        </p>
                        <p>
                            . Ví dụ bạn bạn chọn 40% thì khách nạp thẻ 100.000đ thành công hệ thống sẽ tự động nạp vào
                            tài khoản khách trên site của bạn 60.000đ, trong tài khoản trumthe của bạn sẽ có 65.000đ
                        </p>
                        <p class="mb-1">
                            - Chú ý quan trọng: Nạp sai mệnh giá hoặc sai loại thẻ cào sẽ mất toàn bộ số tiền đã nạp và
                            không hoàn lại với bất cứ lý do nào !
                        </p>
                        <p>
                            Do hệ thống trumthe tự động nên khi bạn nhập sai loại thẻ và mệnh giá hệ thống sẽ tự động
                            nạp thẻ cho khách của trumthe thẻ sai đó, dẫn đến mất thẻ không thể hoàn tiền nếu chọn sai
                            mệnh giá hoặc sai loại thẻ !
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { updatePaymentCard, getPaymentCard } from "@/api/admin"
import { catchError } from "../../../helpers"
export default {
    name: "notification-setting",
    data() {
        return {
            dataAPI: {
                api_key: "",
                api_secret: "",
                charge: 40,
                status: false
            }
        }
    },
    computed: {
        currency() {
            return this.$store.state.site.site.currency
        },
        user() {
            return this.$store.state.user.user
        },
        isMobile() {
            return this.$store.state.app.isMobile
        },
        site() {
            return this.$store.state.site.site
        },
        admin_notification() {
            let noti = {}
            if (this.site && this.site.admin_notification) {
                try {
                    noti = JSON.parse(this.site.admin_notification)
                } catch (error) {
                    noti = {}
                }
            }
            return noti
        }
    },
    created() {
        this.getPaymentCard()
    },
    methods: {
        getPaymentCard: async function() {
            let data = await getPaymentCard()
            if (data.status === 200 && data.success) {
                this.dataAPI.api_key = data.data.api_key
                this.dataAPI.api_secret = data.data.api_secret
                this.dataAPI.charge = data.data.charge
                this.dataAPI.status = data.data.status === 1 ? true : false
            }
        },
        updatePaymentCard: async function() {
            let postData = Object.assign({}, this.dataAPI)
            postData.status = postData.status ? 1 : 0
            let data = await updatePaymentCard(postData)
            if (data.status === 200 && data.success) {
                this.$swal("Thành Công", data.message, "success")
            } else this.$swal("Lỗi", catchError(data), "error")
        }
    }
}
</script>
